document.onload = themePreference();

function themePreference() {
    if (localStorage.getItem("darkLightTheme") === null) {
        window.matchMedia("(prefers-color-scheme: dark)").matches ?
            localStorage.setItem("darkLightTheme", 'dark') : localStorage.setItem("darkLightTheme", 'light');
    }

    var theme = localStorage.getItem("darkLightTheme")
    if(theme == "light") {
        $('#flexSwitchCheckDefault').prop('checked', false);
        $('body').removeClass('dark').addClass('light');
    } else {
        $('#flexSwitchCheckDefault').prop('checked', true);
        $('body').removeClass('light').addClass('dark');
    }
}

$(document).ready(function(){
});

$(document).on('click', '.work-group-row ul li a, .work-group-row div a', function(e){
    e.stopPropagation();
});

$(document).on('click', '.work-group-row', function(e){
    e.preventDefault();
    $(this).toggleClass('openned');
});
$(document).on('click', '#open-all-rows', function(e){
    e.preventDefault();
    $('.work-group-row:not(.openned)').toggleClass('openned');
});


function animateIco(){
    $('#search-input-ico').addClass('left');
}
$(document).on('blur', '#search-input', function(e){
    $('#search-input-ico').removeClass('left');
});
$(document).on('focus', '#search-input', function(e){
    animateIco();
});
$(document).on('click', '#search-input-ico', function(e){
    e.preventDefault();
    animateIco();
    setTimeout(function(){
        $('#search-input').focus();
    }, 250);
});
$(document).on('click', '#flexSwitchCheckDefault', function(e){
    if($(this).is(':checked')) {
        $('body').removeClass('light').addClass('dark');
        localStorage.setItem("darkLightTheme", 'dark');
    } else {
        $('body').removeClass('dark').addClass('light');
        localStorage.setItem("darkLightTheme", 'light');
    }
});

$(document).on('submit', '#newsletter', function(e){
    e.preventDefault();
    var url = $(this).attr("action");
    var data = $(this).serialize();
    $.ajax({
        url: url,
        data: data,
        dataType: "json",
        type: "POST",
        beforeSend: function () {
            $("#loading").show();
        },
        success: function (data) {
            $("#loading").hide();
            $("#newsletter input[name=name]").val("");
            $("#newsletter input[name=email]").val("");
            //console.log(data);
            $("#newsletter .thankyou strong").html(data.message);
            $("#newsletter .thankyou").fadeIn("500").delay(4000).fadeOut(500);
        },
        error: function (x, t, m) {
            if (t === "timeout") {
                console.log("Sync: Request is too long - TIMEOUTED");
            } else {
                console.log("EXCEPTION: " + t);
            }
        }
    });
});
